'use strict';

const debug = {
  values: {},

  init: function () {
    this.element = document.createElement('aside');
    this.element.classList.add('window');
    this.element.classList.add('debug');
    document.body.appendChild(this.element);
  },

  update: function () {
    if (!this.element || renderer.paused) {
      return;
    }

    let html = '';

    const keys = Object.keys(this.values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      html += `<div>${key}: ${this.values[key]}</div>`;
    }

    this.element.innerHTML = html;
  },

  hide: function (key) {
    delete this.values[key];
    this.update();
  },

  show: function (key, value) {
    let doUpdate = false;
    if (!this.values[key]) {
      doUpdate = true;
    } else if (this.values[key] !== value) {
      doUpdate = true;
    }
    this.values[key] = value;
    if (doUpdate) {
      this.update();
    }
  },
};
