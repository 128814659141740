'use strict';

/*

  TODO:

  Tool-specific windows with options
  Modal hotkeys
  Undo / redo
  Copy / paste
  Trigger tiles
  Camera constraints?

*/

const editor = {
  selectedTile: null,
  hoveredTile: null,

  templateVisible: false,
  templateFrame: {
    position: {
      x: 0,
      y: 0,
    },
    width: 30,
    height: 30,
  },

  tools: {
    playerstart: {
      onClick: function (x, y, hitTiles, button) {
        map.mapData.startSpawn.x = x;
        map.mapData.startSpawn.y = y;
      },
    },

    placetiles: {
      onClick: function (x, y, hitTiles, button) {
        const templatePosition = editor.templateFrame.position;

        if (button === 3) {
          if (hitTiles.length > 0 ) {
            map.mapData.tiles = map.mapData.tiles.filter((tile, index) => {
              return hitTiles.indexOf(index) === -1;
            });
          }
        } else {
          map.mapData.tiles.push({
            x: templatePosition.x,
            y: templatePosition.y,
            width: editor.templateFrame.width,
            height: editor.templateFrame.height,
          });
        }
      },

      onOptionsChange: function () {
        editor.templateFrame.width = Number(document.querySelector(
          '[data-toolname="placetiles"] [name="width"]').value);
        editor.templateFrame.height = Number(document.querySelector(
          '[data-toolname="placetiles"] [name="height"]').value);
      },

      onMouseMove: function (x, y, hitTiles) {
        editor.templateVisible = true;
        editor.templateFrame.position.x = x;
        editor.templateFrame.position.y = y;
        editor.templateFrame.position.x -= editor.templateFrame.width / 2;
        editor.templateFrame.position.y -= editor.templateFrame.height / 2;

        if (!input.isDown(input.keys.SHIFT)) {
          const snapUnits = 10;
          editor.templateFrame.position.x =
            Math.floor(editor.templateFrame.position.x / snapUnits) * snapUnits;
          editor.templateFrame.position.y =
            Math.floor(editor.templateFrame.position.y / snapUnits) * snapUnits;
        }
      },

      onSelect: function () {
        editor.templateVisible = true;
      },

      onDeselect: function () {
        editor.templateVisible = false;
      },
    },

    edittiles: {
      onClick: function (x, y, hitTiles, button) {
        if (hitTiles.length) {
          const tile = map.mapData.tiles[hitTiles[0]];
          editor.selectedTile = tile;
          editor.showTileProperties(tile);
        }
      },

      onMouseMove: function (x, y, hitTiles) {
        if (hitTiles.length) {
          const tile = map.mapData.tiles[hitTiles[0]];

          editor.hoveredTile = tile;
        } else {
          editor.hoveredTile = null;
        }
      },

      onOptionsChange: function () {
        if (!editor.selectedTile) {
          return;
        }

        const tile = editor.selectedTile;

        tile.x = tile.xOrigin = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="x"]').value);
        tile.y = tile.yOrigin = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="y"]').value);
        tile.width = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="width"]').value);
        tile.height = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="height"]').value);

        if (!tile.periodicMovement) {
          tile.periodicMovement = {
            x: {
              amount: 0,
              speed: 0,
            },
            y: {
              amount: 0,
              speed: 0,
            },
          };
        }
        if (!tile.periodicMovement.x) {
          tile.periodicMovement.x = {
            amount: 0,
            speed: 0,
          };
        }
        if (!tile.periodicMovement.y) {
          tile.periodicMovement.y = {
            amount: 0,
            speed: 0,
          };
        }

        tile.periodicMovement.x.amount = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="xMovement"]').value);
        tile.periodicMovement.x.speed = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="xVelocity"]').value);
        tile.periodicMovement.y.amount = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="yMovement"]').value);
        tile.periodicMovement.y.speed = Number(document.querySelector(
          '[data-toolname="edittiles"] [name="yVelocity"]').value);
      },
    },
  },

  showTileProperties: function (tile) {
    document.querySelector('[data-toolname="edittiles"] [name="x"]').value =
      tile.xOrigin;
    document.querySelector('[data-toolname="edittiles"] [name="y"]').value =
      tile.yOrigin;
    document.querySelector('[data-toolname="edittiles"] [name="width"]').value
      = tile.width;
    document.querySelector('[data-toolname="edittiles"] [name="height"]').value
      = tile.height;
    if (tile.periodicMovement && tile.periodicMovement.x) {
      document.querySelector(
        '[data-toolname="edittiles"] [name="xMovement"]').value =
        tile.periodicMovement.x.amount;
      document.querySelector(
        '[data-toolname="edittiles"] [name="xVelocity"]').value =
        tile.periodicMovement.x.speed;
    } else {
      document.querySelector(
        '[data-toolname="edittiles"] [name="xMovement"]').value = 0;
      document.querySelector(
        '[data-toolname="edittiles"] [name="xVelocity"]').value = 0;
    }
    if (tile.periodicMovement && tile.periodicMovement.y) {
      document.querySelector(
        '[data-toolname="edittiles"] [name="yMovement"]').value =
        tile.periodicMovement.y.amount;
      document.querySelector(
        '[data-toolname="edittiles"] [name="yVelocity"]').value =
        tile.periodicMovement.y.speed;
    } else {
      document.querySelector(
        '[data-toolname="edittiles"] [name="yMovement"]').value = 0;
      document.querySelector(
        '[data-toolname="edittiles"] [name="yVelocity"]').value = 0;
    }
  },

  init: function () {
    document.querySelector('.editor').classList.remove('hidden');

    document.querySelector('#show-map-data')
    .addEventListener('click', (event) => {
      document.querySelector('.mapdata').classList.remove('hidden');
      document.querySelector('.mapdata textarea').innerHTML =
        JSON.stringify(map.getExport());
      document.querySelector('.mapdata textarea').select();
    });

    document.querySelector('.mapdata .close').addEventListener('click',
    (event) => {
      document.querySelector('.mapdata').classList.add('hidden');
    });

    window.addEventListener('mousedown', this.onMouseDown.bind(this));

    renderer.canvas.addEventListener('click', this.onClick.bind(this));
    renderer.canvas.addEventListener('contextmenu', this.onClick.bind(this));

    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    renderer.canvas.addEventListener('mousewheel',
      this.onMouseWheel.bind(this));

    document.querySelectorAll('.editor .select-tool').forEach((el) => {
      el.addEventListener('click', this.onSelectTool.bind(this));
    });

    document.querySelector('.editor .timescale').addEventListener('input',
      this.onTimescaleChange.bind(this));

    renderer.addRenderCallback(this.render.bind(this));
    camera.automatic = false;
    this.selectTool('placetiles');
    input.addKeyDownListener(input.keys.C, () => {
      camera.automatic = !camera.automatic;
    });

    document.querySelectorAll('.tooloptions').forEach((el) => {
      el.addEventListener('input', this.onTooloptionsChange.bind(this));
      el.addEventListener('change', this.onTooloptionsChange.bind(this));
    });
  },

  onTooloptionsChange: function (event) {
    if (this.currentTool && this.currentTool.onOptionsChange) {
      this.currentTool.onOptionsChange();
    }
  },

  renderFrame: function (x, y, width, height, color) {
    renderer.context.strokeStyle = color;
    renderer.context.strokeRect(
      Math.floor(x),
      Math.floor(y),
      Math.floor(width),
      Math.floor(height));
  },

  render: function (camera) {
    if (this.templateVisible) {
      const screenCoordinate = camera.worldToScreen(
        this.templateFrame.position.x, this.templateFrame.position.y);
      this.renderFrame(screenCoordinate.x, screenCoordinate.y,
        this.templateFrame.width * camera.zoom,
        this.templateFrame.height * camera.zoom, '#3358f3');
    }

    if (this.hoveredTile) {
      const screenCoordinate = camera.worldToScreen(this.hoveredTile.x,
        this.hoveredTile.y);
      this.renderFrame(screenCoordinate.x, screenCoordinate.y,
        this.hoveredTile.width * camera.zoom,
        this.hoveredTile.height * camera.zoom, '#ff00ff');
    }

    if (this.selectedTile) {
      const screenCoordinate = camera.worldToScreen(
        this.selectedTile.x, this.selectedTile.y);
      this.renderFrame(screenCoordinate.x, screenCoordinate.y,
        this.selectedTile.width * camera.zoom,
        this.selectedTile.height * camera.zoom, '#ffffff');
    }
  },

  onTimescaleChange: function (event) {
    renderer.timeScale = event.target.value / 1000;
    console.log(renderer.timeScale);
  },

  onSelectTool: function (event) {
    this.selectTool(event.target.dataset.tool);
  },

  selectTool: function (toolName) {
    if (this.currentTool) {
      document.querySelectorAll('.tooloptions').forEach((element) => {
        element.classList.add('hidden');
      });

      if (this.currentTool.onDeselect) {
        this.currentTool.onDeselect();
      }
    }

    const tool = this.tools[toolName];
    this.currentTool = tool;
    document.querySelectorAll(`.tooloptions[data-toolname=${toolName}]`)
    .forEach((element) => {
      element.classList.remove('hidden');
    });

    if (this.currentTool && this.currentTool.onSelect) {
      this.currentTool.onSelect();
    }
  },

  onMouseWheel: function (event) {
    const minZoom = .00001;
    camera.zoom += Math.sign(event.wheelDelta) * camera.zoom * .1;

    if (camera.zoom < minZoom) {
      camera.zoom = minZoom;
    }
  },

  getPanningDelta: function (x, y) {
    return {
      x: x - this.mouseDownPosition.x,
      y: y - this.mouseDownPosition.y,
    };
  },

  onMouseMove: function (event) {
    if (event.target !== renderer.canvas) {
      return;
    }

    event.preventDefault();
    if (this.mouseDown) {
      const panningDelta = this.getPanningDelta(event.offsetX, event.offsetY);

      if (this.panning) {
        camera.position.x = this.mouseDownPosition.camX -
          panningDelta.x * 1 / camera.zoom;
        camera.position.y = this.mouseDownPosition.camY -
          panningDelta.y * 1 / camera.zoom;
      } else {
        const panningTolerance = 50;
        if (Math.abs(panningDelta.x) + Math.abs(panningDelta.y) >
          panningTolerance) {
          this.panning = true;
          this.mouseDownPosition = {
            x: event.offsetX,
            y: event.offsetY,
            camX: camera.position.x,
            camY: camera.position.y,
          };
        }
      }
    }

    if (this.currentTool && this.currentTool.onMouseMove) {
      const worldPosCenter = camera.screenToWorld(event.offsetX, event.offsetY);
      const hitTiles = map.pickTiles(worldPosCenter.x, worldPosCenter.y);
      this.currentTool.onMouseMove(worldPosCenter.x, worldPosCenter.y,
        hitTiles);
    }
  },

  onMouseDown: function (event) {
    if (event.target !== renderer.canvas) {
      return;
    }

    this.mouseDown = true;
    this.mouseDownPosition = {
      x: event.offsetX,
      y: event.offsetY,
    };
  },

  onClick: function (event) {
    event.preventDefault();
    this.mouseDown = false;
    if (this.panning) {
      this.panning = false;
      return;
    }

    if (this.currentTool && this.currentTool.onClick) {
      const worldPosCenter = camera.screenToWorld(event.offsetX, event.offsetY);
      const hitTiles = map.pickTiles(worldPosCenter.x, worldPosCenter.y);
      this.currentTool.onClick(worldPosCenter.x, worldPosCenter.y, hitTiles,
        event.which);
    }
  },
};
