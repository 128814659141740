'use strict';

const renderer = {
  updateCallbacks: [],
  renderCallbacks: [],
  frameCounter: 0,

  init: function () {
    this.canvas = document.createElement('canvas');
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = 0;
    this.canvas.style.left = 0;
    this.canvas.style.backgroundColor = '#000000';
    this.handleResize();
    document.body.appendChild(this.canvas);
    window.addEventListener('resize', (event) => {
      this.handleResize();
    });
    this.context = this.canvas.getContext('2d');
    this.renderLoop();

    window.addEventListener('blur', () => {
      this.pause();
    });

    window.addEventListener('click', () => {
      if (this.paused) {
        this.resume();
      }
    });

    window.addEventListener('focus', () => {
    });
  },

  handleResize: function () {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
  },

  addUpdateCallback: function (cb) {
    this.updateCallbacks.push(cb);
  },

  addRenderCallback: function (cb) {
    this.renderCallbacks.push(cb);
  },

  update: function (time, delta) {
    for (let i = 0; i < this.updateCallbacks.length; i++) {
      this.updateCallbacks[i](time, delta);
    }
  },

  timeScale: 0,
  time: 0,
  frameDelta: 0,

  internalTime: {
    currentTime: 0,
    lastFrameTime: -1,
    frameDelta: 0,
  },

  pause: function () {
    this.previoustimeScale = this.timeScale;
    this.timeScale = 0;
    this.paused = true;
  },

  resume: function () {
    this.timeScale = this.previoustimeScale || 1;
    this.paused = false;
  },

  render: function (milliseconds) {
    if (this.internalTime.lastFrameTime < 0) {
      this.internalTime.lastFrameTime = milliseconds;
    }
    this.internalTime.currentTime = milliseconds;
    this.internalTime.frameDelta = milliseconds -
      this.internalTime.lastFrameTime;
    this.internalTime.lastFrameTime = milliseconds;

    this.frameDelta = this.internalTime.frameDelta * this.timeScale;
    this.time += this.frameDelta;

    this.update(this.time, this.frameDelta);
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    for (let i = 0; i < this.renderCallbacks.length; i++) {
      this.renderCallbacks[i](camera);
    }

    debug.show('Timescale', this.timeScale);

    this.frameCounter++;
  },

  renderLoop: function (milliseconds) {
    requestAnimationFrame(renderer.renderLoop);
    renderer.render(milliseconds || 0);
  },
};
