'use strict';

const gravity = 9.82;
const pixelsPerMeter = 100;

class PhysicsObject {
  constructor (width, height, mass, position, velocity) {
    this.width = width || 1;
    this.height = height || 1;
    this.mass = mass || 1;
    this.position = position || {x: 0, y: 0};
    this.velocity = velocity || {x: 0, y: 0};
    this.collision = true;

    renderer.addUpdateCallback(this.update.bind(this));
  }

  isAtRest () {
    return !(this.velocity.x || this.velocity.y);
  }

  isFalling () {
    return Boolean(this.velocity.y);
  }

  update (time, delta) {
    const isAtRest = this.isAtRest();
    const deltaSeconds = delta * .001; // 1 / 60 * renderer.timeScale

    let iterations = 0;
    let landed = false;
    this.velocity.y += gravity * pixelsPerMeter * deltaSeconds;
    while (true) {
      let collisionResult = map.collide(
        this.position.x,
        this.position.y,
        this.width, this.height,
        iterations > 0 ? 0 : this.velocity.x,
        0,
        deltaSeconds
      );
      this.velocity.x = iterations > 0 ? this.velocity.x :
        collisionResult.velocity.x;

      this.position.x = collisionResult.position.x;
      this.position.y = collisionResult.position.y;

      collisionResult = map.collide(
        this.position.x,
        this.position.y,
        this.width, this.height,
        0,
        iterations > 0 ? 0 : this.velocity.y,
        deltaSeconds
      );

      this.velocity.y = iterations > 0 ? this.velocity.y :
        collisionResult.velocity.y;

      this.position.x = collisionResult.position.x;
      this.position.y = collisionResult.position.y;

      iterations++;

      if (iterations > 100) {
        console.error('Collision solver stalled!');
        break;
      }

      if (collisionResult.nCollisions < 1) {
        debug.show('iterations', iterations);
        break;
      }
    }
  }
}
