'use strict';

const jumpImpulse = 500;
const horizontalImpulse = 18;
const maxHorizontalVelocity = 200;

const player = {
  init: function () {
    renderer.addUpdateCallback(this.update.bind(this));
    renderer.addRenderCallback(this.render.bind(this));
    input.addKeyDownListener(input.keys.SPACE, this.jump.bind(this));

    input.addKeyDownListener(input.keys.R, () => {
      this.body.position = map.getStartPosition();
      this.body.velocity.x = 0;
      this.body.velocity.y = 0;
    });

    this.body = new PhysicsObject(30, 50, 1, map.getStartPosition());
  },

  jump: function () {
    if (!this.body.isFalling()) {
      this.body.velocity.y = -jumpImpulse;
    }
  },

  update: function (time, delta) {
    const isFalling = this.body.isFalling();

    let movement = 0;
    if (input.isDown(input.keys.LEFT)) {
      movement -= horizontalImpulse;
    }
    if (input.isDown(input.keys.RIGHT)) {
      movement += horizontalImpulse;
    }

    if (!isFalling && !movement) {
      this.body.velocity.x *= .7;
    }

    this.body.velocity.x = Math.max(Math.min(
      this.body.velocity.x + movement,
      maxHorizontalVelocity), -maxHorizontalVelocity);
    debug.show('Player pos',`${Math.floor(this.body.position.x)}, ${Math.floor(this.body.position.y)}`);  // eslint-disable-line
    debug.show('Player velocity', `${Math.floor(this.body.velocity.x)}, ${Math.floor(this.body.velocity.y)}`);    // eslint-disable-line
  },

  render: function (camera) {
    renderer.context.fillStyle = '#ff00ff';
    const worldCoordinate = camera.worldToScreen(this.body.position.x,
      this.body.position.y);
    renderer.context.fillRect(
      Math.floor(worldCoordinate.x),
      Math.floor(worldCoordinate.y),
      this.body.width * camera.zoom, this.body.height * camera.zoom);
  },
};
