'use strict';

const main = {
  init: function () {
    fetch('/maps/test1.json').then(function (response) {
      return response.json();
    }).then(function (mapdata) {
      const editMode = true;

      renderer.init();
      input.init();
      map.init(mapdata);
      player.init();
      camera.init(mapdata.editorCamera.x, mapdata.editorCamera.y);
      if (editMode) {
        debug.init();
        editor.init();
      }

      setTimeout(() => {
        renderer.timeScale = 1;
      }, 200);
    });
  },
};

function documentReady (cb) {
  if (document.readyState !== 'loading') {
    cb();
  } else {
    document.addEventListener('DOMContentLoaded', cb);
  }
}

documentReady(function () {
  main.init();
});
