'use strict';

const map = {
  init: function (mapData) {
    this.mapData = mapData;
    this.movingTiles = [];
    for (let i = 0; i < this.mapData.tiles.length; i++) {
      const tile = this.mapData.tiles[i];
      tile.xOrigin = tile.x;
      tile.yOrigin = tile.y;
      if (tile.periodicMovement) {
        this.movingTiles.push(tile);
      }
    }

    renderer.addUpdateCallback(this.update.bind(this));
    renderer.addRenderCallback(this.render.bind(this));
  },

  getStartPosition: function () {
    return {
      x: this.mapData.startSpawn.x,
      y: this.mapData.startSpawn.y,
    };
  },

  getExport: function () {
    for (let i = 0; i < this.mapData.tiles.length; i++) {
      const tile = this.mapData.tiles[i];
      delete tile.lastCandidature;
      delete tile.lastCollision;
      if (tile.periodicMovement) {
        tile.x = tile.xOrigin;
        tile.y = tile.yOrigin;
      }
    }

    return this.mapData;
  },

  pickTiles: function (x, y) {
    let hitTiles = [];
    for (let i = 0; i < this.mapData.tiles.length; i++) {
      const tile = this.mapData.tiles[i];
      if (CollisionUtils.pointIsInRect(x, y, tile.x, tile.y, tile.width,
        tile.height)) {
        hitTiles.push(i);
      }
    }

    return hitTiles;
  },

  update: function (time, delta) {
    for (let i = 0; i < this.movingTiles.length; i++) {
      const tile = this.movingTiles[i];
      let dx = 0;
      let dy = 0;
      if (tile.periodicMovement.x) {
        dx = tile.xOrigin + Math.sin(renderer.time * .001 * Math.PI *
          tile.periodicMovement.x.speed) * tile.periodicMovement.x.amount -
          tile.x;
      }

      if (tile.periodicMovement.y) {
        dy = tile.yOrigin + Math.sin(renderer.time * .001 * Math.PI *
          tile.periodicMovement.y.speed) * tile.periodicMovement.y.amount -
          tile.y;
      }

      tile.x += dx;
      tile.y += dy;
      if (tile.lastCollision >= renderer.frameCounter - 1) {
        player.body.position.x += dx;
        player.body.position.y += dy;
      }
    }
  },

  render: function (camera) {
    for (let i = 0; i < this.mapData.tiles.length; i++) {
      const tile = this.mapData.tiles[i];
      renderer.context.fillStyle = '#005500';
      if (this.showCollisions) {
        if (tile.lastCollision === renderer.frameCounter) {
          renderer.context.fillStyle = '#ff0000';
        } else if (tile.lastCandidature === renderer.frameCounter) {
          renderer.context.fillStyle = '#ff00ff';
        }
      }
      const worldCoordinate = camera.worldToScreen(tile.x, tile.y);
      renderer.context.fillRect(
        Math.floor(worldCoordinate.x),
        Math.floor(worldCoordinate.y),
        Math.ceil(tile.width * camera.zoom),
        Math.ceil(tile.height * camera.zoom));
    }

    renderer.context.fillStyle = '#827203';
    const worldCoordinate = camera.worldToScreen(this.mapData.startSpawn.x,
      this.mapData.startSpawn.y);

    renderer.context.fillRect(
      Math.floor(worldCoordinate.x),
      Math.floor(worldCoordinate.y),
      Math.ceil(5 * camera.zoom), Math.ceil(5 * camera.zoom));
  },

  getCollisionCandidates: function (objectLeft, objectTop, objectWidth,
    objectHeight, dx, dy) {
    let candidates = [];
    const manhattanMovement = Math.abs(dx) + Math.abs(dy) + objectWidth;
    const objectCenter = {
      x: objectLeft + objectWidth / 2,
      y: objectTop + objectHeight / 2,
    };

    for (let i = 0; i < this.mapData.tiles.length; i++) {
      const tile = this.mapData.tiles[i];
      const manhattanDistance = Math.abs(objectCenter.x -
        (tile.x + tile.width / 2)) + Math.abs(objectCenter.y - (tile.y +
          tile.height / 2));
      if (manhattanDistance < manhattanMovement + tile.width) {
        candidates.push(tile);
        tile.lastCandidature = renderer.frameCounter;
      }
    }

    debug.show('Candidates', candidates.length);
    return candidates;
  },

  collide: function (x, y, objectWidth, objectHeight, vx, vy, delta) {
    const dx = vx * delta;
    const dy = vy * delta;
    let objectTop = y + dy;
    let objectLeft = x + dx;
    let nCollisions = 0;

    const candidates = this.getCollisionCandidates(objectLeft, objectTop,
      objectWidth, objectHeight, dx, dy);

    for (let i = 0; i < candidates.length; i++) {
      const tile = candidates[i];
      if (CollisionUtils.rectsOverlap(tile.x, tile.y, tile.width, tile.height,
        objectLeft, objectTop, objectWidth, objectHeight)) {
        tile.lastCollision = renderer.frameCounter;
        const tileRight = tile.x + tile.width;
        const tileBottom = tile.y + tile.height;
        const objectRight = objectLeft + objectWidth;
        const objectBottom = objectTop + objectHeight;
        let xOverlap = 0;
        let yOverlap = 0;

        if (objectRight >= tile.x && objectRight <= tileRight) {
          xOverlap = objectRight - tile.x;
        } else if (objectLeft >= tile.x && objectLeft <= tileRight) {
          xOverlap = objectLeft - tileRight;
        }

        if (objectBottom >= tile.y && objectBottom <= tileBottom) {
          yOverlap = objectBottom - tile.y;
        } else if (objectTop >= tile.y && objectTop <= tileBottom) {
          yOverlap = objectTop - tileBottom;
        }

        if (xOverlap && Math.abs(xOverlap) <
          (yOverlap ? Math.abs(yOverlap) : 9999)) {
          objectLeft -= xOverlap;
          vx = tile.velocity ? tile.velocity.x : 0;
        } else if (yOverlap) {
          objectTop -= yOverlap;
          vy = tile.velocity ? tile.velocity.y : 0;
        }
        nCollisions++;
      }
    }

    return {
      nCollisions: nCollisions,
      velocity: {
        x: vx,
        y: vy,
      },
      position: {
        x: objectLeft,
        y: objectTop,
      },
    };
  },
};
