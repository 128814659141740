'use strict';

const input = {
  keys: {
    SHIFT: 16,
    LEFT: 37,
    RIGHT: 39,
    UP: 38,
    DOWN: 40,
    SPACE: 32,
    R: 82,
    C: 67,
  },

  keyState: [],
  keydownListeners: [],
  logEvents: false,

  init: function () {
    window.addEventListener('keydown', (event) => {
      if (event.repeat) {
        return;
      }

      if (this.logEvents) {
        console.log(`${event.which} pressed`);
      }

      this.keyState[event.which] = true;

      if (this.keydownListeners[event.which]) {
        const listeners = this.keydownListeners[event.which];
        for (let i = 0; i < listeners.length; i++) {
          listeners[i]();
        }
      }
    });

    window.addEventListener('keyup', (event) => {
      if (this.logEvents) {
        console.log(`${event.which} released`);
      }
      this.keyState[event.which] = false;
    });
  },

  addKeyDownListener: function (key, cb) {
    if (!this.keydownListeners[key]) {
      this.keydownListeners[key] = [];
    }

    this.keydownListeners[key].push(cb);
  },

  isDown: function (key) {
    return Boolean(this.keyState[key]);
  },
};
