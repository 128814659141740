'use strict';

const CollisionUtils = {
  rectsOverlap: function (x1, y1, w1, h1, x2, y2, w2, h2) {
    const right1 = x1 + w1;
    const right2 = x2 + w2;
    const bottom1 = y1 + h1;
    const bottom2 = y2 + h2;

    return !(x2 >= right1 ||
           right2 <= x1 ||
           y2 >= bottom1 ||
           bottom2 <= y1);
  },

  pointIsInRect: function (x, y, x2, y2, w2, h2) {
    return x >= x2 && x <= x2 + w2 && y >= y2 && y <= y2 + h2;
  },
};
