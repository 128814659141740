'use strict';

const camera = {
  position: {
    x: 0,
    y: 0,
  },
  zoom: 1,

  init: function (x, y) {
    this.position.x = x || 0;
    this.position.y = y || 0;
    this.automatic = true;
    renderer.addUpdateCallback(this.update.bind(this));
  },

  trackPlayer: function (delta) {
    const d = {
      x: player.body.position.x + player.body.velocity.x * 3 - this.position.x,
      y: player.body.position.y + player.body.velocity.y * 3 - this.position.y,
    };
    camera.position.x += d.x * delta * .0008;
    camera.position.y += d.y * delta * 0;
  },

  update: function (time, delta) {
    if (this.automatic) {
      this.trackPlayer(delta);
    }
  },

  screenToWorld: function (x, y) {
    return {
      x: (x - renderer.canvas.width / 2) / this.zoom + this.position.x,
      y: (y - renderer.canvas.height / 2) / this.zoom + this.position.y,
    };
  },

  worldToScreen: function (x, y) {
    return {
      x: renderer.canvas.width / 2 + (x - this.position.x) * this.zoom,
      y: renderer.canvas.height / 2 + (y - this.position.y) * this.zoom,
    };
  },
};
