const EventListenerMode = {capture: true};

function preventGlobalMouseEvents () {
  document.body.style['pointer-events'] = 'none';
}

function restoreGlobalMouseEvents () {
  document.body.style['pointer-events'] = 'auto';
}

function mousemoveListener (e) {
  e.stopPropagation ();
  if (window.captureMoveCallback) {
    window.captureMoveCallback(e);
  }
}

function mouseupListener (e) {
  if (window.captureClickCallback) {
    const dx = Math.abs(window.captureDownX - e.clientX);
    const dy = Math.abs(window.captureDownY - e.clientY);
    if (dx + dy < 10) {
      window.captureClickCallback(e);
    }
  }
  restoreGlobalMouseEvents ();
  document.removeEventListener ('mouseup',   mouseupListener,   EventListenerMode);
  document.removeEventListener ('mousemove', mousemoveListener, EventListenerMode);
  e.stopPropagation ();
}

function captureMouseEvents (e, callback, clickCallback) {
  preventGlobalMouseEvents ();
  window.captureMoveCallback = callback;
  window.captureClickCallback = clickCallback;
  window.captureDownX = e.clientX;
  window.captureDownY = e.clientY;
  document.addEventListener ('mouseup',   mouseupListener,   EventListenerMode);
  document.addEventListener ('mousemove', mousemoveListener, EventListenerMode);
  e.preventDefault ();
  e.stopPropagation ();
}
